import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Section, Container } from "@components/global";

const SCHOLARSHIP_QUERY = graphql`
  query {
    datoCmsScholarship {
      pdf {
        url
        filename
      }
      instructionsNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

const Scholarship = () => {
  const downloadFile = (fileType, url) => {
    fetch(url, {
      method: "GET",
    }).then((res) =>
      res.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "grist-mill-application.pdf";
        a.click();
      })
    );
  };

  const { datoCmsScholarship } = useStaticQuery(SCHOLARSHIP_QUERY);

  return (
    <Section id="scholarship" accent="secondary">
      <Container style={{ position: "relative" }}>
        <Flex>
          <Blurb>
            <h1>Scholarship</h1>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  datoCmsScholarship.instructionsNode.childMarkdownRemark.html,
              }}
            ></div>
          </Blurb>
          <Line></Line>
          <Downloads>
            <button
              aria-label="Scholarship PDF download"
              onClick={() => downloadFile("pdf", datoCmsScholarship.pdf.url)}
            >
              Download Scholarship Form
            </button>
          </Downloads>
        </Flex>
      </Container>
    </Section>
  );
};

const Flex = styled.div`
  display: flex;
  flex-direction: row-reverse;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-direction: column;
  }
`;

const Line = styled.div`
  border: 1px solid black;
  margin: 0 1rem;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    border: none;
    margin-bottom: 1rem;
  }
`;

const Blurb = styled.div`
  text-align: left;
  p {
    margin-top: 1rem;
  }

  br {
    margin-bottom: 0.5rem;
  }
`;

const Downloads = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  button {
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.black.light};
    text-decoration: underline;
    border-radius: 3px;

    & :hover {
      background: ${(props) => props.theme.color.primary};
      color: ${(props) => props.theme.color.black.regular};
    }
  }
`;

export default Scholarship;
