import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import faces from "../../images/grist-mill-logo.jpg";
import { Container } from "@components/global";

const Header = () => (
  <HeaderWrapper>
    <Container>
      <Grid>
        <Art></Art>
        <Text>
          <h1>
            Grist Mill
            <br />
            Fine Arts
          </h1>
          <span>
            Contact Us: <br />
            email -- gristmillmacedonia@gmail.com
            <br />
            phone -- 712-486-2216
          </span>
        </Text>
      </Grid>
    </Container>
  </HeaderWrapper>
);

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.primary};
  padding-top: 96px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding-top: 128px;
  }
`;

const Art = styled.div`
  width: 100%;
  height: 400px;
  margin: 0;
  background: url(${faces}) no-repeat;
  background-size: contain;
  background-position: center;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    height: 200px;
    margin-bottom: 50px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;

  span {
    display: block;
    margin-top: 16px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: start;
  }
`;

export default Header;
