import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Section, Container } from "@components/global";
import venmo from "../../images/venmo.jpg";

const Showing = () => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsCurrentShowing {
          showVenmo
          title
          image {
            alt
            fluid(maxWidth: 600) {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
        }
        datoCmsPreviousShowing {
          showVenmo
          displayPreviousShowing
          title
          image {
            alt
            fluid(maxWidth: 600) {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Section id="showing">
          <StyledContainer>
            <h2>{data.datoCmsCurrentShowing.title}</h2>
            <Art>
              <Img fluid={data.datoCmsCurrentShowing.image.fluid} />
            </Art>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.datoCmsCurrentShowing.descriptionNode.childMarkdownRemark
                    .html,
              }}
            ></div>
          </StyledContainer>
          {data.datoCmsCurrentShowing.showVenmo ? <Venmo /> : <div />}
        </Section>
        {data.datoCmsPreviousShowing.displayPreviousShowing && (
          <Section id="previous-showing" accent>
            <StyledContainer>
              <h2>{data.datoCmsPreviousShowing.title}</h2>
              <Art>
                <Img fluid={data.datoCmsPreviousShowing.image.fluid} />
              </Art>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.datoCmsPreviousShowing.descriptionNode
                      .childMarkdownRemark.html,
                }}
              ></div>
            </StyledContainer>
            {data.datoCmsPreviousShowing.showVenmo ? <Venmo /> : <div />}
          </Section>
        )}
      </>
    )}
  />
);

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;

  h2 {
    margin-bottom: 20px;
    font-weight: bold;
  }

  p {
    margin-top: 20px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const Art = styled.div`
  width: 100%;
  max-width: 600px;
`;

const Venmo = styled.div`
  width: 100%;
  height: 400px;
  margin: 0;
  background: url(${venmo}) no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: 25px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    height: 300px;
  }
`;

export default Showing;
