import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

const Faq = () => {
  return (
    <Section id="faq">
      <Container>
        <Flex>
          <Blurb>
            <h1>Got a Question?</h1>
            <p>
              Each year the Grist Mill Fine Arts Council board members get
              together to discuss the next year's activities and shows. While we
              try to make the best lineup possible, we just know there are ideas
              and talented actors/singers we haven't seen and heard. <br />
              Here's your chance! Whether you have an idea for a show or you'd
              like to be IN the show, fill out the form to let us know all about
              it!
            </p>
          </Blurb>
          <Line></Line>
          <Suggestion>
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />{' '}
              <input type="hidden" name="form-name" value="contact" />
              <label>First</label>
              <input type="text" name="first" />
              <label>Last</label>
              <input type="text" name="last" />
              <label>Email</label>
              <input type="text" name="email" />
              <label>Comment</label>
              <textarea name="comment" />
              {/* {errors.exampleRequired && <p>This field is required</p>} */}
              <input type="submit" />
            </form>
          </Suggestion>
        </Flex>
      </Container>
    </Section>
  );
};

const Flex = styled.div`
  display: flex;
  flex-direction: row-reverse;
  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
  }
`;

const Line = styled.div`
  border: 1px solid black;
  margin: 0 1rem;
  @media (max-width: ${props => props.theme.screen.md}) {
    border: none;
    margin-bottom: 1rem;
  }
`;

const Blurb = styled.div`
  p {
    margin-top: 1rem;
  }

  br {
    margin-bottom: 0.5rem;
  }
`;

const Suggestion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
  }

  form {
    display: flex;
    flex-direction: column;
  }
  label {
    padding-left: 0.5rem;
  }
  input {
    border-radius: 3px;
    border: 1px solid ${props => props.theme.color.black.light};
    margin-bottom: 0.5rem;
    padding: 0.25rem 0.5rem;
    &[type='submit'] {
      margin-top: 1rem;
      padding: 0.5rem;
    }
  }
  textarea {
    border-radius: 3px;
    border: 1px solid ${props => props.theme.color.black.light};
    margin-bottom: 0.5rem;
    padding: 0.25rem 0.5rem;
  }
`;

export default Faq;
