import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { Section, Container } from '@components/global';

const Sponsors = () => {
  const data = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Friends" } }) {
        edges {
          node {
            data {
              Name
              Level
            }
          }
        }
      }
    }
  `).allAirtable;

  return (
    <Section id="sponsors" accent="secondary">
      <Container style={{ position: 'relative' }}>
        <h1>Grist Mill Friends</h1>
        <Flex>
          <ul>
            <li>
              <span>Patrons</span>
            </li>
            {data.edges
              .filter(edge => edge.node.data.Level === 'Patron')
              .map(person => (
                <li>{person.node.data.Name}</li>
              ))}
          </ul>
          <ul>
            <li>
              <span>Sponsors</span>
            </li>
            {data.edges
              .filter(edge => edge.node.data.Level === 'Sponsor')
              .map(person => (
                <li>{person.node.data.Name}</li>
              ))}
          </ul>
          <ul>
            <li>
              <span>Benefactors</span>
            </li>
            {data.edges
              .filter(edge => edge.node.data.Level === 'Benefactor')
              .map(person => (
                <li>{person.node.data.Name}</li>
              ))}
          </ul>
          <ul>
            <li>
              <span>Sustainers</span>
            </li>
            {data.edges
              .filter(edge => edge.node.data.Level === 'Sustainer')
              .map(person => (
                <li>{person.node.data.Name}</li>
              ))}
          </ul>
        </Flex>
      </Container>
    </Section>
  );
};

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }

  ul {
    padding: 0;
  }

  li {
    list-style: none;

    > span {
      font-weight: bold;
      font-size: 1.5rem;
    }
    &:nth-child(1) {
      margin-bottom: 0.5rem;
    }
  }
`;

export default Sponsors;
