import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Section, Container } from "@components/global";
import { airtableBase } from "../../airtable";

const letterList = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
];

const Seats = () => {
  const [selectedDay, setSelectedDay] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getSeats = async () => {
      const seats = [];
      airtableBase("Reservations")
        .select({
          fields: [
            "Seat",
            "Seat Index",
            "Row",
            "Friday Reservation",
            "Saturday Payment",
            "Saturday Reservation",
            "Sunday Payment",
            "Sunday Reservation",
          ],
          sort: [{ field: "Seat Index", direction: "asc" }],
        })
        .eachPage(
          (pageRecords, fetchNextPage) => {
            pageRecords.forEach((record) => {
              seats.push(record.fields);
            });
            fetchNextPage();
          },
          (err) => {
            setData(seats);
          }
        );
    };
    getSeats();
  }, []);

  return (
    <Section id="seats" accent="secondary">
      <Container style={{ position: "relative" }}>
        <Flex>
          <Selector selectedDay={selectedDay}>
            <h1>Availability</h1>
            <h3>Select a Day to View</h3>
            <ul>
              <li>
                <button
                  aria-label="View available seats for Friday"
                  onClick={() => setSelectedDay(1)}
                >
                  Friday
                </button>
              </li>
              <li>
                <button
                  aria-label="View available seats for Saturday"
                  onClick={() => setSelectedDay(2)}
                >
                  Saturday
                </button>
              </li>
              <li>
                <button
                  aria-label="View available seats for Sunday"
                  onClick={() => setSelectedDay(3)}
                >
                  Sunday
                </button>
              </li>
            </ul>
          </Selector>
          {data && (
            <Grid>
              <Stage>
                <span>Stage</span>
              </Stage>
              <Walkway>
                <span>Walkway</span>
              </Walkway>
              <TopSeats>
                {data.slice(0, 60).map((edge) =>
                  selectedDay === 1 ? (
                    <Seat
                      key={`seat_${edge["Seat Index"]}`}
                      reserved={edge["Friday Reservation"] ?? false}
                    >
                      {edge["Seat"]}
                    </Seat>
                  ) : selectedDay === 2 ? (
                    <Seat
                      key={`seat_${edge["Seat Index"]}`}
                      reserved={edge["Saturday Reservation"] ?? false}
                    >
                      {edge["Seat"]}
                    </Seat>
                  ) : (
                    <Seat
                      key={`seat_${edge["Seat Index"]}`}
                      reserved={edge["Sunday Reservation"] ?? false}
                    >
                      {edge["Seat"]}
                    </Seat>
                  )
                )}
              </TopSeats>
              <LettersTop>
                {letterList.slice(0, 6).map((letter) => (
                  <div key={letter}>{letter}</div>
                ))}
              </LettersTop>
              <HandicapRow>
                <span>Wide Aisle</span>
              </HandicapRow>
              <BottomSeats>
                {data.slice(60, 110).map((edge) =>
                  selectedDay === 1 ? (
                    <Seat
                      key={`seat_${edge["Seat Index"]}`}
                      reserved={edge["Friday Reservation"] ?? false}
                    >
                      {edge["Seat"]}
                    </Seat>
                  ) : selectedDay === 2 ? (
                    <Seat
                      key={`seat_${edge["Seat Index"]}`}
                      reserved={edge["Saturday Reservation"] ?? false}
                    >
                      {edge["Seat"]}
                    </Seat>
                  ) : (
                    <Seat
                      key={`seat_${edge["Seat Index"]}`}
                      reserved={edge["Sunday Reservation"] ?? false}
                    >
                      {edge["Seat"]}
                    </Seat>
                  )
                )}
                <>
                  <div></div>
                  <div></div>
                  <div></div>
                  {data.slice(110, 117).map((edge) =>
                    selectedDay === 1 ? (
                      <Seat
                        key={`seat_${edge["Seat Index"]}`}
                        reserved={edge["Friday Reservation"] ?? false}
                      >
                        {edge["Seat"]}
                      </Seat>
                    ) : selectedDay === 2 ? (
                      <Seat
                        key={`seat_${edge["Seat Index"]}`}
                        reserved={edge["Saturday Reservation"] ?? false}
                      >
                        {edge["Seat"]}
                      </Seat>
                    ) : (
                      <Seat
                        key={`seat_${edge["Seat Index"]}`}
                        reserved={edge["Sunday Reservation"] ?? false}
                      >
                        {edge["Seat"]}
                      </Seat>
                    )
                  )}
                </>
                <>
                  <div></div>
                  <div></div>
                  <div></div>
                  {data.slice(117, 124).map((edge) =>
                    selectedDay === 1 ? (
                      <Seat
                        key={`seat_${edge["Seat Index"]}`}
                        reserved={edge["Friday Reservation"] ?? false}
                      >
                        {edge["Seat"]}
                      </Seat>
                    ) : selectedDay === 2 ? (
                      <Seat
                        key={`seat_${edge["Seat Index"]}`}
                        reserved={edge["Saturday Reservation"] ?? false}
                      >
                        {edge["Seat"]}
                      </Seat>
                    ) : (
                      <Seat
                        key={`seat_${edge["Seat Index"]}`}
                        reserved={edge["Sunday Reservation"] ?? false}
                      >
                        {edge["Seat"]}
                      </Seat>
                    )
                  )}
                </>
                <>
                  <div></div>
                  <div></div>
                  {data.slice(124).map((edge) =>
                    selectedDay === 1 ? (
                      <Seat
                        key={`seat_${edge["Seat Index"]}`}
                        reserved={edge["Friday Reservation"] ?? false}
                      >
                        {edge["Seat"]}
                      </Seat>
                    ) : selectedDay === 2 ? (
                      <Seat
                        key={`seat_${edge["Seat Index"]}`}
                        reserved={edge["Saturday Reservation"] ?? false}
                      >
                        {edge["Seat"]}
                      </Seat>
                    ) : (
                      <Seat
                        key={`seat_${edge["Seat Index"]}`}
                        reserved={edge["Sunday Reservation"] ?? false}
                      >
                        {edge["Seat"]}
                      </Seat>
                    )
                  )}
                </>
              </BottomSeats>
              <LettersBottom>
                {letterList.slice(6).map((letter) => (
                  <div key={letter}>{letter}</div>
                ))}
              </LettersBottom>
            </Grid>
          )}
        </Flex>
      </Container>
    </Section>
  );
};

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 100%;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-direction: column;
  }
`;

const Selector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  ul {
    padding: 0;
  }
  li {
    list-style: none;

    &:nth-child(${(props) => props.selectedDay}) {
      > button {
        background: #444;
        color: ${(props) => props.theme.color.white.dark};
        outline: none;
      }
    }
  }
  button {
    width: 200px;
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.black.light};
    border-radius: 3px;
    background: ${(props) => props.theme.color.primary};
    margin: 1rem 0;
    & :hover {
      background: ${(props) => props.theme.color.primary};
      color: ${(props) => props.theme.color.black.regular};
    }
  }
`;

const Grid = styled.article`
  display: grid;
  grid-template-areas:
    "stage stage stage"
    "walk letters-top top"
    "walk hand hand"
    "walk letters-bot bottom";
  grid-template-columns: 10fr 5fr 85fr;
  grid-template-rows: 10fr 39fr 9fr 42fr;
  height: 500px;
  width: 350px;
  margin: 0 auto;
  border: 1px solid black;
  border-radius: 5px;
  overflow: hidden;
  max-width: 100%;
  direction: rtl;
`;

const Stage = styled.div`
  grid-area: stage;
  height: 100%;
  background: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: ${(props) => props.theme.color.white.dark};
`;

const Walkway = styled.div`
  grid-area: walk;
  background: #bbb;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    transform: rotate(-90deg);
  }
`;

const TopSeats = styled.div`
  grid-area: top;
  background: #ccc;
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 4px;
`;

const HandicapRow = styled.div`
  grid-area: hand;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bbb;
`;

const BottomSeats = styled.div`
  grid-area: bottom;
  background: #ccc;
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 4px;
`;

const Seat = styled.div`
  height: 100%;
  font-size: 0.8rem;
  background: ${(props) => (props.reserved ? "#222" : "#eee")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LettersTop = styled.div`
  grid-area: letters-top;
  display: grid;
  background: #ccc;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 4px;
  padding: 1rem 0.5rem 1rem 0;

  > div {
    display: flex;
    align-items: center;
  }
`;
const LettersBottom = styled.div`
  grid-area: letters-bot;
  display: grid;
  background: #ccc;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, 1fr);
  grid-gap: 4px;
  padding: 1rem 0.5rem 1rem 0;

  > div {
    display: flex;
    align-items: center;
  }
`;

export default Seats;
