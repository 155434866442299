import React from "react";
import styled from "styled-components";

import { Container } from "@components/global";

const Footer = () => (
  <>
    <FooterWrapper>
      <StyledContainer>
        <Copyright>
          <h2>Grist Mill Fine Arts</h2>
          <span>
            Contact Us: <br />
            email -- gristmillmacedonia@gmail.com
            <br />
            phone -- 712-486-2216
          </span>
        </Copyright>
      </StyledContainer>
    </FooterWrapper>
  </>
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.primary};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Art = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 48px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
