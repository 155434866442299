import React from "react";

import Layout from "@common/Layout";
import Navbar from "@common/Navbar";

import Header from "@sections/Header";
import About from "@sections/About";
import Showing from "@sections/Showing";
import Seats from "@sections/Seats";
import Scholarship from "@sections/Scholarship";
import Camp from "@sections/Camp";
import Sponsors from "@sections/Sponsors";
import Faq from "@sections/Faq";
import Footer from "@sections/Footer";
import { useStaticQuery, graphql } from "gatsby";

const IndexPage = () => {
  const { showSeatingChart } = useStaticQuery(
    INDEX_QUERY
  ).datoCmsCurrentShowing;

  return (
    <Layout>
      <Navbar showSeatingChart={showSeatingChart} />
      <Header />
      <Showing />
      {showSeatingChart && <Seats />}
      <About />
      <Scholarship />
      <Camp />
      <Faq />
      <Sponsors />
      <Footer />
    </Layout>
  );
};

const INDEX_QUERY = graphql`
  query {
    datoCmsCurrentShowing {
      showSeatingChart
    }
  }
`;

export default IndexPage;
